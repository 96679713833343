import React from 'react'

const Hubspot = () => {
  return (
    <>
      <script
        type="text/javascript"
        id="hs-script-loader"
        async
        defer
        src="//js.hs-scripts.com/21855166.js"
      ></script>
    </>
  )
}

export default Hubspot
