import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  html {
    font-size: 62.5%;
  }
  html, body, #__next {
    height: 100%;
    scroll-behavior: smooth;
  }
  body, button {
    background: #fff;
    font-family: 'AkkoPro';
  }
  input, select, option {
    font-family: 'AkkoPro';
  }
  a {
    text-decoration: none;
    color: #1e1853;
    transition: 0.2s all;

    &:hover {
      color: #09c3f4;
    }
  }

  .text-rise {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }

  .text-rise-on {
    opacity: 1;
    transform: translateY(0);
  }

  @font-face {
    font-family: "AkkoPro";
    src: url("/fonts/AkkoPro-Regular.otf");
    font-style: normal;
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: "AkkoPro";
    src: url("/fonts/AkkoPro-Medium.otf");
    font-style: normal;
    font-weight: 500;
    font-display: swap;
  }

  @font-face {
    font-family: "AkkoPro";
    src: url("/fonts/AkkoPro-Bold.otf");
    font-style: normal;
    font-weight: 700;
    font-display: swap;
  }

`

export default GlobalStyles
